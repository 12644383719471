<template>
  <div class="box">
    <div class="header">
      <div class="header-left">
        <img @click="home()" src="../assets/logo.png" alt="">
        <span style="cursor: pointer;" @click="home()">·数字化应用超市</span>
        <div class="input">
          <!--          <el-input placeholder="搜索应用系统" v-model="Title" style="width:100%;margin-left: 20px" clearable>-->
          <!--          </el-input>-->
          <!--          <img src="../assets/search.png" alt="">-->
          <el-select v-model="titel" filterable placeholder="请选择" @change="details(titel)"
                     style="width:100%;margin-left: 20px" clearable>
            <el-option
                v-for="item in serchList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="header-right" v-if="name==''">
        <el-button style="margin-left: 20px" type="text" size="medium" @click="login()">登录</el-button>
        <el-button style="margin-left: 20px" type="primary" size="medium" round>免费注册</el-button>
      </div>
      <div class="header-right" v-if="name!=''">
        <el-button type="text">{{ name }}</el-button>
        <el-button style="margin-left: 20px" type="primary" size="mini" round @click="quit()">退出登录</el-button>
      </div>
    </div>

    <div class="content" style="margin-top: 85px">
<!--      <div class="content-header"  id="contentHeader" style="width: 70%;margin:0 auto;height: 120px">-->
        <div :class="['content-header', { 'sticky': isStickyHeader }]"  id="contentHeader" style="width: 70%;margin:0 auto;height: 120px">
        <div class="header-left">
          <div class="app-img">
            <!--            <img src="../assets/logo1.png" alt="">-->
            <img :src="imageUrl+data.picture" alt="">
          </div>
          <div class="app-briefly">
            <div class="app-name">{{ data.titel }}</div>
            <div class="desc">{{ data.remark }}</div>
            <div style="height: 32px"></div>
            <div class="app-install">安装：{{ data.installNum }}次</div>
          </div>
        </div>
        <div class="header-right">
          <div class="get-free" @click="handleAdd()" v-if="collect">
            免费获取
          </div>
          <div class="get-free" @click="handleDel()" v-if="!collect">
            取消
          </div>
        </div>
      </div>
<!--            <div class="header-bottom" id="headerBottom">-->
             <div :class="['header-bottom', { 'sticky': isStickyBottom }]" id="headerBottom">
              <div class="header-bottom-ul">
                <div
                    class="header-bottom-li"
                    v-for="(item, index) in menuItems"
                    :key="index"
                    :class="{ active: activeIndex === index }"
                    @click="setActive(index)"
                >
                  {{ item }}
                </div>
              </div>
            </div>

      <div class="details">
        <div class="details-header" id="section0">
          <img src="../assets/xiexian.png" alt="">
          <span style="margin: 0px 10px"> 详细介绍</span>
          <img src="../assets/xiexian.png" alt="">
        </div>
        <div class="details-text">
          {{ data.detail }}
        </div>
        <div class="details-pc" id="section1">
          <div class="details-pc-header" >
            <img src="../assets/computer.png" alt="">
            <span>PC端</span>
          </div>
          <div class="pc-ul" >
            <el-image v-for="(item,index) in pcImage" :key="index"
                      style="width: calc(50% - 50px + 30px / 2);
                      height: 295px;
                      margin-right: 30px;
                      background: #FFFFFF;
                      border: 2px solid #E6EFFF;
                      border-radius: 10px;
                      cursor: pointer;
                      margin-bottom: 20px"
                      :src="item"
                      :preview-src-list="pcImage">
            </el-image>
            <!--            <img style="margin-bottom: 30px" :src="imageUrl+data.pcImg1" alt="">-->
            <!--            <img style="margin-bottom: 30px" :src="imageUrl+data.pcImg2" alt="">-->
            <!--            <img :src="imageUrl+data.pcImg3" alt="">-->
            <!--            <img :src="imageUrl+data.pcImg4" alt="">-->
          </div>
        </div>

        <div class="details-pc" style="margin-top: 30px" id="section2">
          <div class="details-pc-header"  >
            <img src="../assets/phone.png" alt="">
            <span>移动端</span>
          </div>
          <div class="phone-ul" style="display: flex">
            <el-image v-for="(item,index) in ydImage" :key="index"
                      style="width: calc(25% - 38px + 24px / 4);
                      height: 593px;
                      margin-right: 24px;
                      background: #FFFFFF;
                      border: 4px solid #E6EFFF;
                      border-radius: 10px;
                      cursor: pointer;"
                      :src="item"
                      :preview-src-list="ydImage">
            </el-image>
            <!--            <img :src="imageUrl+data.mobileImg1" alt="">-->
            <!--            <img :src="imageUrl+data.mobileImg2" alt="">-->
            <!--            <img :src="imageUrl+data.mobileImg3" alt="">-->
            <!--            <img :src="imageUrl+data.mobileImg4" alt="">-->
          </div>
        </div>

        <div class="details-header" id="section3">
          <img src="../assets/xiexian.png" alt="">
          <span style="margin: 0px 10px"> 功能列表</span>
          <img src="../assets/xiexian.png" alt="">
        </div>
        <div style="text-align: left" v-html="processedHtml">

        </div>

      </div>

    </div>

    <div class="footer">
      <div class="container">
        <div class="container-left">
          <div class="container-left-top">
            <a href="./"> 产品主页</a>
            <a href="https://www.elinkhoping.com/business.html" target="_blank"> 数字赋能</a>
            <a href="https://www.elinkhoping.com/index.html" target="_blank"> 关于我们</a>
            <a href="https://www.elinkhoping.com/joinUs.html" target="_blank"> 商务合作</a>
            <span> 服务热线：400-1787170</span>
          </div>
          <div class="container-left-bottom">
            Copyright © 版权所有 好品易链（山东）科技发展有限公司 鲁ICP备19035836号-6
          </div>
        </div>
        <div class="container-right">
          <img
              src="https://www.elinkhoping.com/prod-api/profile/upload/2024/01/19/好品易链公众号_20240119162300A036.jpg"
              alt="">
        </div>

      </div>

    </div>

    <!-- 添加或修改系统分类类型对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" style="border-radius: 12px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="联系人" prop="contract">
          <el-input v-model="form.contract" placeholder="请输入联系人"/>
        </el-form-item>
        <el-form-item label="联系电话" prop="telephone">
          <el-input v-model="form.telephone" placeholder="请输入联系电话"/>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group v-model="form.userType">
            <el-radio label="0">个人</el-radio>
            <el-radio label="1">单位</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="单位名称" v-if="form.userType=='1'" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请输入单位名称"/>
        </el-form-item>

        <!--        <el-form-item label="标题" prop="title">-->
        <!--          <el-input v-model="form.title" placeholder="请输入标题" />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="标题介绍" prop="contentDesc">-->
        <!--          <el-input v-model="form.contentDesc" type="textarea"  :rows="4" placeholder="请输入标题介绍"  />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="排序" prop="dataSort">-->
        <!--          <el-input v-model="form.dataSort" type="number"  :rows="4" placeholder="请输入"  />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="备注" prop="remark">-->
        <!--          <el-input v-model="form.remark" type="textarea" placeholder="请输入备注" />-->
        <!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {queryTemplate} from "../../api/index"
import {getDetails, addRecord, queryRecord, delRecord} from "../../api/indexDetails"
import {imageurl} from "../../util/request"
import {logout} from "../../api/login";

export default {
  data() {
    return {
      Title: "",
      menuItems: ['详细介绍', 'PC图片', '手机图片', '功能介绍'], // 菜单项列表
      // menuItems: [], // 菜单项列表
      activeIndex: 0, // 默认选中的菜单项索引
      imageUrl: "",
      rawHtml: "",
      data: {},
      open: false,
      title: "",
      form: {
        userType: "0"
      },
      rules: {
        contract: [
          {required: true, message: "请填写联系人", trigger: "blur"}
        ],
        telephone: [
          {required: true, message: "请填写联系电话", trigger: "blur"}
        ],
        userType: [
          {required: true, message: "请填写类型", trigger: "blur"}
        ]
      },
      name: "",
      titel: "",
      serchList: [],
      collect: true,
      collectId: "",
      pcImage: [],
      ydImage: [],
      isStickyHeader: false, // 控制content-header吸顶
      isStickyBottom: false, // 控制header-bottom吸顶
    }
  },

  created() {
    this.imageUrl = imageurl
    this.imageUrl = imageurl
    if (localStorage.getItem("nickName")) {
      this.name = localStorage.getItem("nickName")
    }
    this.fetchDetails();
    this.getSerch()
  },

  methods: {
    async fetchDetails(id) {
      if (id) {
        try {
          const res = await getDetails(id);
          this.data = res.data; // 异步赋值
          this.pcImage = []
          this.ydImage = []
          var pcImg1 = this.imageUrl + res.data.pcImg1
          var pcImg2 = this.imageUrl + res.data.pcImg2
          var pcImg3 = this.imageUrl + res.data.pcImg3
          var pcImg4 = this.imageUrl + res.data.pcImg4
          this.pcImage.push(pcImg1)
          this.pcImage.push(pcImg2)
          this.pcImage.push(pcImg3)
          this.pcImage.push(pcImg4)

          var mobileImg1 = this.imageUrl + res.data.mobileImg1
          var mobileImg2 = this.imageUrl + res.data.mobileImg2
          var mobileImg3 = this.imageUrl + res.data.mobileImg3
          var mobileImg4 = this.imageUrl + res.data.mobileImg4
          this.ydImage.push(mobileImg1)
          this.ydImage.push(mobileImg2)
          this.ydImage.push(mobileImg3)
          this.ydImage.push(mobileImg4)
          this.getRecord()

        } catch (error) {
          // console.error("Error fetching details:", error);
        }
      } else {
        try {
          const res = await getDetails(this.$route.query.id);
          this.data = res.data; // 异步赋值
          this.pcImage = []
          this.pcImage = []
          if(res.data.pcImg1){
            var pcImg1 = this.imageUrl + res.data.pcImg1
            this.pcImage.push(pcImg1)
          }
          if(res.data.pcImg2){
            var pcImg2 = this.imageUrl + res.data.pcImg2
            this.pcImage.push(pcImg2)
          }
          if(res.data.pcImg3){
            var pcImg3 = this.imageUrl + res.data.pcImg3
            this.pcImage.push(pcImg3)
          }
          if(res.data.pcImg4){
            var pcImg4 = this.imageUrl + res.data.pcImg4
            this.pcImage.push(pcImg4)
          }


          if(res.data.mobileImg1){
            var mobileImg1 = this.imageUrl + res.data.mobileImg1
            this.ydImage.push(mobileImg1)
          }
          if(res.data.mobileImg2){
            var mobileImg2 = this.imageUrl + res.data.mobileImg2
            this.ydImage.push(mobileImg2)
          }
          if(res.data.mobileImg3){
            var mobileImg3 = this.imageUrl + res.data.mobileImg3
            this.ydImage.push(mobileImg3)
          }
          if(res.data.mobileImg4){
            var mobileImg4 = this.imageUrl + res.data.mobileImg4
            this.ydImage.push(mobileImg4)
          }




          this.getRecord()
        } catch (error) {
          // console.error("Error fetching details:", error);
        }
      }

    },
    //查询是否获取过
    getRecord() {
      var userId = localStorage.getItem("userId");
      var applicationId = this.data.id
      queryRecord(userId, applicationId).then(res => {
        if (res.data == null) {
          this.collect = true
        } else {
          this.collectId = res.data.id;
          this.collect = false
        }
      })
    },
    //取消获取
    handleDel() {
      var id = this.collectId
      delRecord(id).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          return this.fetchDetails();
        }
      })
    },
    //打开弹出层
    handleAdd() {
      this.open = true;
      this.title = "联系信息";
    },
    //关闭弹窗
    cancel() {
      this.open = false;
    },
    //表单提交
    submitForm() {
      this.$refs.form.validate(valid => {
        this.form.userId = localStorage.getItem("userId")
        this.form.userName = localStorage.getItem("userName")
        this.form.applicationId = this.data.id
        this.form.applicationName = this.data.titel
        console.log(this.form)
        if (valid) {
          addRecord(this.form).then(res => {
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success'
              });
              this.fetchDetails();
              this.open = false;
            }
          })
        }
      })

    },
    details(id) {
      this.fetchDetails(id)
    },

    //跳转去首页
    home() {
      this.$router.push('/')
    },
    //条件查询
    getSerch() {
      var typeId = ''
      var typeDataId = ''
      queryTemplate(typeId, typeDataId, this.titel).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          res.data[i].label = res.data[i].titel
          res.data[i].value = res.data[i].id
        }
        this.serchList = res.data
      })
    },

    //点击切换标签
    setActive(index) {
      this.activeIndex = index; // 更新选中索引
      const sectionId = `section${index}`; // 构造目标元素的 ID
      const element = document.getElementById(sectionId);
      if (element) {
        const offsetTop = element.offsetTop - 80;
        window.scrollTo({ top: offsetTop, behavior: "smooth" }); // 平滑滚动
      }
    },
    //吸顶
    handleScroll() {
      const contentHeaderHeight = document.getElementById("contentHeader").offsetHeight;
      const scrollTop = window.scrollY;

      // 控制 content-header 吸顶状态
      this.isStickyHeader = scrollTop > 0;

      // 控制 header-bottom 吸顶状态
      this.isStickyBottom = scrollTop >= contentHeaderHeight;
    },
    //退出登录
    quit() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        logout().then(res => {
          console.log(res)
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.name = ''
            localStorage.removeItem("Authorization"); // 清除本地存储的 Token
            localStorage.removeItem("nickName"); // 清除本地存储的 Token
            localStorage.removeItem("Token"); // 清除本地存储的 Token
            localStorage.removeItem("userName"); // 清除本地存储的 Token
            localStorage.removeItem("userId"); // 清除本地存储的 Token
            this.$router.push('/');
          }
        })
      }).catch(() => {
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },


  computed: {
    //处理功能列表数据
    processedHtml() {
      // console.log("Processed HTML data:", this.data, this.imageUrl);

      // 检查数据和 imageUrl 是否正确
      if (!this.data.featureList || !this.imageUrl) {
        // console.warn("FeatureList or ImageURL is missing.");
        return '';
      }
      // 替换图片地址中的 "/dev-api"
      const replacedHtml = this.data.featureList.replace(/src="\/dev-api(.*?)"/g, (match, p1) => {
        // 拼接 imageUrl 并返回新的 src
        return `src="${this.imageUrl}${p1}"`;
      });
      // console.log("Replaced HTML:", replacedHtml);
      // 处理转义字符
      return replacedHtml.replace(/\\\"/g, '"').replace(/\\\\/g, '\\');
    },
  },


}
</script>
<style scoped>
.content-header.sticky {
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 10;
  background: #fff;
  left: 15%;
}

.header-bottom.sticky {
  position: fixed;
  top: 135px; /* 根据 content-header 的高度设置 */
  width: 100%;
  z-index: 9;
  padding-top: 30px;
  background: #fff;
}
.box {
  width: 100%;
  height: auto;
}

.header {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
}

.header-left {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;

}

.header-left img {
  width: 145px;
}

.header-left span {
  font-size: 20px;
  font-weight: 500;
  color: #0265FF;
  margin-left: 5px;
}

.input {
  width: 62%;
  position: relative;
}

.input img {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 25px;
  top: 8px;
}

.header-right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menu {
  width: 22px;
  height: 22px;
}

.content-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content-header.header-left {
  display: flex;
  align-items: center;
  max-width: calc(100% - 230px);
}

.app-img {
  flex: none;
  width: 279px;
  height: 120px;
  margin-right: 20px;
}

.app-img img {
  width: 100%;
  height: 100%;
}

.app-briefly {
  line-height: 1;
  text-align: justify;
}

.app-name {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.desc {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-install {
  font-size: 14px;
  font-weight: 500;
  color: #4D609F;
  margin-right: 20px;
}

.content-header .header-right {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: end;
  justify-content: flex-end;
}

.get-free {
  width: 120px;
  height: 42px;
  line-height: 42px;
  background: #0265FF;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
}

.header-bottom {
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  margin-top: 48px;
}

.header-bottom-ul {
  width: 70%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
}

.header-bottom-li {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  height: 32px;
  margin-right: 40px;
  cursor: pointer;
  position: relative;
}

.header-bottom-li.active {
  color: #0265FF;
}

.header-bottom-li.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #0265FF;
}

.details {
  width: 70%;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.details-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.details-header img {
  width: 18px;
  height: 19px;
}

.details-text {
  width: 100%;
  height: auto;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 26px;
  padding: 20px 0px;
  box-sizing: border-box;
  text-align: start;
}

.details-pc {
  width: 100%;
  height: auto;
}

.details-pc-header {
  width: 100%;
  height: 30px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.details-pc-header img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.details-pc-header span {
  font-size: 14px;
  color: #333333
}

.pc-ul {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.pc-ul img {
  width: calc(50% - 50px + 30px / 2);
  height: 295px;
  margin-right: 30px;
  background: #FFFFFF;
  border: 2px solid #E6EFFF;
  border-radius: 10px;
  cursor: pointer;
}

.phone-ul .el-img {
  width: calc(25% - 38px + 24px / 4);
  height: 593px;
  margin-right: 24px;
  background: #FFFFFF;
  border: 4px solid #E6EFFF;
  border-radius: 10px;
  cursor: pointer;
}

.footer {
  width: 100%;
  height: 137px;
  background-color: #F4F5F7;
}

.container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.container-left {
  width: 90%;
  height: 100%;
  margin-top: 30px;
}

.container-left a {
  margin-right: 20px;
  cursor: pointer;
  color: #666666;
  font-size: 13px;
  text-decoration: none;
}

.container-left-bottom {
  margin-top: 50px;
}

.container-right img {
  width: 120px;
  height: 120px;
  margin-top: 10px;
}
</style>
