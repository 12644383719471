//首先导入刚刚封装好的request.js文件  路径存储根据自己修改
import request from "../util/request";



// 获取验证码
export function getCodeImg() {
    return request({
        url: '/captchaImage',
        headers: {
            isToken: false
        },
        method: 'get',
        timeout: 20000
    })
}


// 登录方法
export function login(username, password, code, uuid) {
    const data = {
        username,
        password,
        code,
        uuid
    }
    return request({
        url: '/login',
        headers: {
            isToken: false,
            repeatSubmit: false
        },
        method: 'post',
        data: data
    })
}
//退出登录
export function logout() {
    return request({
        headers: {
            isToken: true
        },
        url: '/logout',
        method: 'post'
    })
}
// //地区列表
// export function GeteGionList() {
//     return request({
//         method:'GEt',
//         url:'/region/list'
//     })
// }
//
// //添加收货地址
// export function setShippingAddress(data) {
//     return request({
//         method:'POST',
//         url:"/address/setShippingAddress",
//         data: data
//     })
// }
//
// //设置默认地址
// export function Altedstate(id) {
//     return request({
//         method:'GET',
//         url:'/address/default',
//         params: {
//             id:id
//         }
//
//     })
// }
//
// //获取订单列表
// export function GetOrder(pages,id) {
//     return request({
//         method:'get',
//         params: {
//             page:pages.page,
//             limit:pages.limit,
//             id:id,
//         },
//         url:`/order/list`,
//     })
// }
//
// //添加订单
// export function PostAddorder(data) {
//     return request({
//         data:data,
//         method:'POST',
//         url:'/order/goods',
//     })
// }
//
// //添加商品收藏
// export function PostAddinsert(id) {
//     return request({
//         method:'GET',
//         url:`/collect/insert?id=${id} `,
//     })
// }
// // 获取收藏
// export function Getcollect() {
//     return request({
//         method:'GET',
//         url:`/collect/list`,
//     })
// }
// // 删除收藏
// export function Deletdcollect(arr) {
//     return request({
//         method:'post',
//         data: {
//             id:arr
//         },
//         url:`/collect/delete`,
//     })
// }
//
// //获取商品足迹
// export function Getfootprint() {
//     return request({
//         method:'get',
//         url:`/footprint/select`,
//     })
// }
//
// // 获取首页的左边工具栏
// // http://localhost:8081/wx/catalog/list?page=1&limit=11
// export function setToolList () {
//     return request.get('/catalog/list', {
//         params: {
//             page: 1,
//             limit: 20
//         }
//     })
// }

