<template>
  <div class="box">
    <div class="header">
      <img src="../assets/logo1.png" alt="">
      好品易链
    </div>

    <div class="rightCont">
      <div class="title">欢迎使用</div>
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <el-form-item prop="nickName">
          <el-input v-model="loginForm.nickName" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item prop="phonenumber">
          <el-input v-model="loginForm.phonenumber" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="verifyCode">
          <div style="display: flex;justify-content: space-between">
            <el-input style="width: 50%;" v-model="loginForm.verifyCode" placeholder="请输入验证码"></el-input>
            <el-button v-if="Code"   type="primary" style="width: 40%;" @click="code()">发送验证码</el-button>
            <el-button v-if="!Code" type="primary" :loading="true" style="width: 40%;">发送中</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="">
          <el-button  type="primary"  style="width: 100%;" @click="zhuce()">立即注册</el-button>
        </el-form-item>
      </el-form>
      <div class="text">
        <span class="number" @click="login()">账号密码登录</span>
        <span style="margin: 5px">|</span>
        <span class="password" >注册验证码登录</span>
      </div>



    </div>

  </div>
</template>
<script>
import {getCode,registerAddUser} from "../../api/register"
export default {
  data() {
    return {
      loading:false,
      Code:true,
      loginForm:{
        nickName: "",
        phonenumber: "",
        password: "",
        verifyCode: ""
      },
      loginRules:{
        nickName: [
          { required: true, trigger: "blur", message: "请输入您的昵称" }
        ],
        phonenumber: [
          { required: true, trigger: "blur", message: "请输入您的手机号" }
        ],
        password: [{ required: true, trigger: "change", message: "请输入密码" }],
      verifyCode:[{ required: true, trigger: "change", message: "请输入验证码" }]
      }
    }
  },
  methods: {
    //获取验证码
    code(){
      if(this.loginForm.phonenumber == ""){
        return this.$message.error('请输入手机号');
      }
      var obj={
        phone:this.loginForm.phonenumber,
      }
      getCode(obj).then(res=>{
        console.log(res)
        if(res.code == 200){
          this.Code=false;
        }
      })
    },
    //注册
    zhuce(){
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          console.log("aaaaa",this.loginForm)
          this.loginForm.userName = this.loginForm.phonenumber;
          registerAddUser(this.loginForm).then(res=>{
            console.log(res)
            if(res.code == 200){
             this.$router.push("/login");
            }else {
              return this.$message.error(res.msg);
            }
          })
        }
      });
    },

    //去登录
    login(){
      this.$router.push('/login')
    }
  },
  mounted() {

  },
}
</script>
<style scoped>
.box {
  width: 100%;
  height: 100vh;
  cursor: pointer;
}
.header{
  width: 95%;
  margin: 0 auto;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-overflow: ellipsis;
  font-weight: 700;
  color: #0265FF;
  margin-top: 30px;
}
.header img{
  height: 1.5rem;
  margin-right: 5px;
}
.rightCont{
  display: inline-block;
  vertical-align: middle;
  width: 18.6875rem;
  height: 25.3125rem;
  background: #fff;
  box-shadow: 0 0 1rem rgba(40, 135, 203, 0.3);
  border-radius: .625rem;
  padding: 3rem 2.5rem 0;
  position: fixed;
  top: 20%;
  left: 40%;
}

.title{
  font-size: 1.375rem;
  margin-bottom: 1.5625rem;
  font-weight: 700;
  color: #000;
  white-space: nowrap;
  min-height: 1.875rem;
  display: flex;
}
.code{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.code /deep/.el-input{
  width: 55%;
}
.code img{
  width: 40%;
  height: 40px;
}
.login{
  margin-top: 20px;
}
/deep/.el-select .el-input {
  width: 150px;
}
/deep/.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.text{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
}


.password{
  color: #0265ff
}
.number:hover{
  color: #0265ff;
}
</style>
