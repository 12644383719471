//首先导入刚刚封装好的request.js文件  路径存储根据自己修改
import request from "../util/request";



// 获取验证码
export function getCode(data) {
    return request({
        url: '/system/user/getCode?phone=' + data.phone,
        // headers: {
        //     isToken: false
        // },
        method: 'post',
        // data: data
    })
}

//注册接口
export function registerAddUser(data) {
    return request({
        url: '/system/user/registerAddUser',
        // headers: {
        //     isToken: false
        // },
        method: 'post',
        data: data
    })
}

