<template>
  <div class="box">
    <div class="header">
      <img src="../assets/logo1.png" alt="">
      好品易链
    </div>

    <div class="rightCont">
          <div class="title">欢迎使用</div>
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" ></el-input>
        </el-form-item>
        <el-form-item prop="code" >
          <div style="display: flex;justify-content: space-between">
            <el-input
                v-model="loginForm.code"
                auto-complete="off"
                placeholder="验证码"
                style="width: 63%"
            >
            </el-input>
            <div class="login-code">
              <img :src="codeUrl" @click="getCode" class="login-code-img"/>
            </div>
          </div>

        </el-form-item>
      <div class="rightCont-bottom">
        <el-form-item prop="rememberMe">
          <el-checkbox v-model="loginForm.rememberMe">下次自动登录</el-checkbox>
        </el-form-item>

        <el-button type="text" @click="forgotPassword()">忘记密码</el-button>
      </div>

      <div class="login">
<!--        <el-button type="primary" style="width: 100%;" @click="login()">登录</el-button>-->
        <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="width:100%;"
            @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </div>
      </el-form>

      <div class="text">
        <span class="number">账号密码登录</span>
        <span style="margin: 5px">|</span>
        <span class="password" @click="register()">注册验证码登录</span>
      </div>

    </div>

  </div>
</template>
<script>
import {getCodeImg,login} from "../../api/login"
import Cookies from "js-cookie";
export default {
  name:"login",
  data() {
    return {
      loading:false,
      checked: false,
      codeUrl:"",
      loginForm:{
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules:{
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }]
      }
    }
  },
  created() {
    this.getCode()
    const savedUsername = Cookies.get("username");
    const savedPassword = Cookies.get("password");
    const rememberMe = Cookies.get("rememberMe");

    if (savedUsername && savedPassword && rememberMe === "true") {
      this.loginForm.username = savedUsername;
      this.loginForm.password = savedPassword;
      this.loginForm.rememberMe = true;
    }
  },

  methods: {
    //获取验证码
    getCode(){
      getCodeImg().then(res => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.loginForm.uuid = res.uuid;
      })
    },
    //登录
    handleLogin(){
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", this.loginForm.password, { expires: 30 });
            Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });

          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          login(this.loginForm.username,this.loginForm.password,this.loginForm.code,this.loginForm.uuid).then(res => {
            if(res.code==200){
              console.log(res)
              // this.loading = false;
              localStorage.setItem('Token', res.token);
              this.$router.push("/");
            }else {
              this.loading = false;
              this.getCode()
              return this.$message.error(res.msg);
            }
          })
        }
      });
    },
    //忘记密码
    forgotPassword(){
      this.$router.push('/forgotPassword')
    },
    //去注册
    register(){
      this.$router.push('/register')
    },
  },
  mounted() {

  },
}
</script>
<style scoped >
.box {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.header{
  width: 95%;
  margin: 0 auto;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-overflow: ellipsis;
  font-weight: 700;
  color: #0265FF;
  margin-top: 30px;
}
.header img{
  height: 1.5rem;
  margin-right: 5px;
}
.rightCont{
  display: inline-block;
  vertical-align: middle;
  width: 18.6875rem;
  height: 25.3125rem;
  background: #fff;
  box-shadow: 0 0 1rem rgba(40, 135, 203, 0.3);
  border-radius: .625rem;
  padding: 3rem 2.5rem 0;
  position: fixed;
  top: 20%;
  left: 40%;
}

.title{
  font-size: 1.375rem;
  margin-bottom: 1.5625rem;
  font-weight: 700;
  color: #000;
  white-space: nowrap;
  min-height: 1.875rem;
  display: flex;
}
.rightCont-bottom{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 20px;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;

}
.login-code img {
  cursor: pointer;
  vertical-align: middle;
  width: 100%;
}
.number{
  color: #0265ff
}
.password:hover{
  color: #0265ff;
}
</style>
