<template>
  <div class="box">
    <div class="header">
      <div class="header-left">
        <img src="../assets/logo.png" alt="">
        <span style="cursor: pointer;">·数字化应用超市</span>
        <div class="input">
<!--          <el-input placeholder="搜索应用系统" v-model="Title" style="width:100%;margin-left: 20px" clearable>-->
<!--          </el-input>-->
          <el-select v-model="titel" filterable placeholder="请选择" @change="details(titel)" style="width:100%;margin-left: 20px" clearable>
            <el-option
                v-for="item in serchList"
                :key="item.value"
                :label="item.label"
                :value="item.value" >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="header-right" v-if="name==''">
<!--        <img class="menu" src="../assets/menu.png" alt=""></img>-->
<!--        <span style="margin-left: 20px">|</span>-->
        <el-button style="margin-left: 20px" type="text" size="medium" @click="login()">登录</el-button>
        <el-button style="margin-left: 20px" type="primary" size="medium" round @click="register()">免费注册</el-button>
      </div>
      <div class="header-right"  v-if="name!=''">
        <el-button type="text">{{name}}</el-button>
        <el-button style="margin-left: 20px" type="primary" size="mini" round @click="quit()">退出登录</el-button>
      </div>
    </div>


      <el-carousel  style="margin-top: 65px">
        <el-carousel-item v-for="(item,index) in banList" :key="index">
          <img style="width: 100%;height: auto" :src="imageUrl+item.homePhoto" alt="">
        </el-carousel-item>
      </el-carousel>

    <div class="image">
      <img src="../assets/sanduan.png" alt="">
      <img src="../assets/duijie.png" alt="">
      <img src="../assets/bushu.png" alt="">
<!--      <img src="../assets/bushu.png" alt="">-->
    </div>



    <el-container  style="margin-top: 50px;margin-left: 90px">
      <!-- 左侧侧边栏 -->
      <el-aside width="230px" class="sidebar">
        <el-menu
            :default-active="activeMenu"
            class="el-menu-vertical-demo"
            @select="handleMenuSelect"
            unique-opened
        >
          <div v-for="menu in menus" :key="menu.id">
            <el-submenu
                v-if="menu.dasSystemTypeDataList && menu.dasSystemTypeDataList.length > 0"
                :index="menu.id.toString()"
            >
              <template #title>
                <span :class="{ 'active-menu': activeMenu.startsWith(menu.id.toString()) }"
                      style="display: flex;justify-content: flex-start;font-weight: 600">
                  {{ menu.title }}
                </span>
              </template>
              <el-menu-item
                  v-for="sub in menu.dasSystemTypeDataList"
                  :key="sub.id"
                  :index="`${menu.id}-${sub.id}`"
              >
                <span style="display: flex;justify-content: flex-start;margin-left: 10px"> {{ sub.dictLabel }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item
                v-else
                :index="menu.id.toString()"
                :key="menu.id"
            >
              <span  v-if="menu.id=='3'" :class="{'active':isActive}" style="display: flex;justify-content: flex-start;font-weight: 600;">{{ menu.title }}</span>
              <span v-else style="display: flex;justify-content: flex-start;font-weight: 600">{{ menu.title }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </el-aside>

      <!-- 右侧内容区 -->
      <el-main class="content">
        <div class="right-box" v-if="selectedContent">
          <div class="right-box-content" v-for="(item, index) in List" :key="index" @click="details(item.id)">
            <div class="right-box-content-top">
<!--              <img src="../assets/logo1.png" alt="">-->
              <img :src="imageUrl+item.picture" alt="">
            </div>
            <div class="right-box-content-top-content">
              <div class="right-box-content-top-content-title"> {{item.titel}}</div>
              <p>{{item.remark}}</p>
            </div>
            <div class="right-box-content-bottom">
              <div class="right-box-content-bottom-left">
                <div class="price" style="font-size: 0.75rem; font-weight: 500;color: #666666;"> 免费 </div>
                <div class="install-num" style="font-size: 0.75rem;font-weight: 500;color: #4D609F; margin-left: 1rem;">安装次数:{{item.installNum}}次</div>
              </div>
              <div class="right-box-content-bottom-right">
                <div class="get-btn">获取</div>
              </div>
            </div>
          </div>

        </div>
<!--        <div v-else>-->
<!--          <p>请选择一个菜单项</p>-->
<!--        </div>-->
      </el-main>
    </el-container>

    <div style="height: 137px"></div>
    <div class="footer">
      <div class="container">
        <div class="container-left">
          <div class="container-left-top">
            <a> 产品主页</a>
            <a href="https://www.elinkhoping.com/business.html" target="_blank"> 数字赋能</a>
            <a href="https://www.elinkhoping.com/index.html" target="_blank"> 关于我们</a>
            <a href="https://www.elinkhoping.com/joinUs.html" target="_blank"> 商务合作</a>
            <span> 服务热线：400-1787170</span>
          </div>
          <div class="container-left-bottom">
            Copyright © 版权所有 好品易链（山东）科技发展有限公司 鲁ICP备19035836号-6
          </div>
        </div>
        <div class="container-right">
          <img src="https://www.elinkhoping.com/prod-api/profile/upload/2024/01/19/好品易链公众号_20240119162300A036.jpg" alt="">
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {queryList, queryTemplate,getInfo,banner,premium,Newest} from "../../api/index"
import {logout} from "../../api/login"
import  {imageurl} from "../../util/request"
import {Message} from "element-ui";
export default {
  data() {
    return {
      activeMenu: "", // 当前激活的菜单项
      selectedContent: null, // 当前选中的内容
      menus: [],
      premiumApp: [],// 精品应用数据
      titel: "",
      List:[],
      imageUrl:"",
      name:"",
      serchList:[],
      banList:[],
      isActive:true,

    }
  },
  created() {
    this.getBanner();
    this.getPremium();
    this.GetList();
    this.getUser();
    this.getSerch();
    this.imageUrl = imageurl;
  },
  methods: {
    // 获取首页轮播图
    getBanner() {
      banner().then(res => {
        this.banList = res.data;
      });
    },

    // 获取精品应用右侧数据
    getPremium() {
      premium().then(res => {
        if (res && res.data && res.data.length > 0) {
          this.List =res.data
        } else {
          this.List = [];
          Message.error("精品应用无内容！");
        }
      });
    },

    getNewest(){
      Newest().then(res => {
        if (res && res.data && res.data.length > 0) {
          this.List =res.data
        } else {
          this.List = [];
          Message.error("精品应用无内容！");
        }
      })
    },

    // 获取左侧菜单
    GetList() {
      queryList().then(res => {
        this.menus = res.data;

        // 查找是否有“精品应用”菜单
        const premiumMenu = this.menus.find(menu => menu.title === "精品应用");

        if (premiumMenu) {
          // 如果有精品应用菜单，默认加载它的右侧数据
          // this.activeMenu = "premium";
          this.selectedContent = premiumMenu;
          this.getPremium(); // 调用方法加载精品应用右侧数据
        } else {
          // 过滤掉没有子级菜单的菜单
          this.menus = this.menus.filter(
              menu =>
                  menu.dasSystemTypeDataList && menu.dasSystemTypeDataList.length > 0
          );

          const firstMenu = this.menus[0];
          if (firstMenu) {
            this.activeMenu = `${firstMenu.id}-${firstMenu.dasSystemTypeDataList[0].id}`;
            this.selectedContent = firstMenu.dasSystemTypeDataList[0];
            this.fetchContentData(
                this.selectedContent.typeId,
                this.selectedContent.id
            );
          } else {
            Message.error("没有可加载的菜单内容！");
          }
        }
      });
    },

    // 点击左侧菜单
    handleMenuSelect(index) {
      console.log(index)
      // this.activeMenu = index;
      // 判断是否是精品应用
      if (index =="3") {
        this.isActive=true
        this.selectedContent = { title: "精品应用" }; // 设置当前选中内容为精品应用
        this.getPremium(); // 调用独立接口加载精品应用内容
        return; // 阻止后续逻辑执行
      }

      if (index =="17") {
        this.isActive=false
        this.selectedContent = { title: "最新上线" }; // 设置当前选中内容为精品应用
        this.getNewest(); // 调用独立接口加载精品应用内容
        return; // 阻止后续逻辑执行
      }

      // 处理其他菜单项
      this.isActive=false
      const [menuId, subId] = index.split("-").map(Number);
      const selectedMenu = this.menus.find(menu => menu.id === menuId);

      if (selectedMenu && subId) {
        this.selectedContent = selectedMenu.dasSystemTypeDataList.find(
            child => child.id === subId
        );
      } else {
        this.selectedContent = selectedMenu;
      }

      this.fetchContentData(menuId, subId || null); // 加载其他菜单数据
    },

    // 查询右侧数据
    fetchContentData(typeId, typeDataId) {
      if (typeId) {
        queryTemplate(typeId, typeDataId, "").then(res => {
          if (res.data && res.data.length > 0) {
            this.List = res.data
            // this.List = res.data.map(item => ({
            //   label: item.titel,
            //   value: item.id,
            // }));
          } else {
            this.List = [];
            Message.error("没有内容！");
          }
        });
      } else {
        this.List = [];
        Message.error("没有子目录！");
      }
    },

    // 条件查询
    getSerch() {
      let typeId = "";
      let typeDataId = "";
      queryTemplate(typeId, typeDataId, this.titel).then(res => {
        this.serchList = res.data.map(item => ({
          label: item.titel,
          value: item.id,
        }));
      });
    },

    // 获取用户信息
    getUser() {
      if (localStorage.getItem("Token")) {
        getInfo().then(res => {
          localStorage.setItem("userName", res.user.userName);
          localStorage.setItem("userId", res.user.userId);
          localStorage.setItem("nickName", res.user.nickName);
          if (localStorage.getItem("nickName")) {
            this.name = localStorage.getItem("nickName");
          }
        });
      }
    },
    //跳转去详情
      details(id){
        if (localStorage.getItem("Token")) {
          this.$router.push("/indexDdetails?id="+id)
        }else {
          return   Message.error("请先登录！");
        }
      },


      //去登录
      login() {
        this.$router.push('/login')
      },
      //去注册
      register(){
        this.$router.push('/register')
      },
      //退出登录
      quit(){
        this.$confirm('确定注销并退出系统吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          logout().then(res => {
            console.log(res)
            if(res.code == 200){
              this.$message({
                message: res.msg,
                type: 'success'
              });
              this.name=''
              localStorage.removeItem("Authorization"); // 清除本地存储的 Token
              localStorage.removeItem("nickName"); // 清除本地存储的 Token
              localStorage.removeItem("Token"); // 清除本地存储的 Token
              localStorage.removeItem("userName"); // 清除本地存储的 Token
              localStorage.removeItem("userId"); // 清除本地存储的 Token
            }
          })
        }).catch(() => {});
      },
  },

  // created() {
  //   this.getBanner()
  //   this.getPremium()
  //   this.GetList()
  //   this.getUser()
  //   this.getSerch()
  //   this.imageUrl=imageurl
  //
  // },
  //
  // methods: {
  //   //获取首页轮播图
  //   getBanner(){
  //     banner().then(res => {
  //       this.banList=res.data
  //     })
  //   },
  //   //获取精品应用
  //   getPremium(){
  //     premium().then(res => {
  //       console.log(res)
  //     })
  //   },
  //   //获取左侧菜单
  //   GetList() {
  //     queryList().then(res => {
  //       this.menus = res.data;
  //       const firstMenu = this.menus[0];
  //       if (firstMenu && firstMenu.dasSystemTypeDataList && firstMenu.dasSystemTypeDataList.length > 0) {
  //         this.activeMenu = `${firstMenu.id}-${firstMenu.dasSystemTypeDataList[0].id}`;
  //         this.selectedContent = firstMenu.dasSystemTypeDataList[0];
  //         // console.log("初始化", this.selectedContent.typeId, this.selectedContent.id);
  //         this.fetchContentData(this.selectedContent.typeId, this.selectedContent.id);
  //       }
  //     })
  //   },
  //   //点击左侧菜单
  //   handleMenuSelect(index) {
  //     this.activeMenu = index;
  //     const [menuId, subId] = index.split("-").map(Number);
  //     const selectedMenu = this.menus.find((menu) => menu.id === menuId);
  //
  //     if (selectedMenu && subId) {
  //       this.selectedContent = selectedMenu.dasSystemTypeDataList.find((child) => child.id === subId);
  //     } else {
  //       this.selectedContent = selectedMenu;
  //     }
  //     let result = index.split("-")
  //     this.fetchContentData(result[0], result[1]); // 根据子菜单 ID 查询右侧数据
  //
  //   },
  //   //查询右侧数据
  //   fetchContentData(typeId, typeDataId) {
  //     if(typeId && typeDataId){
  //     let titel = ""
  //     queryTemplate(typeId, typeDataId, titel).then(res => {
  //       for(var i=0;i<res.data.length;i++){
  //         res.data[i].label =res.data[i].titel
  //         res.data[i].value =res.data[i].id
  //       }
  //       this.List=res.data
  //     })
  //     }else {
  //       Message.error("没有子目录！");
  //     }
  //
  //   },
  //
  //   //条件查询
  //   getSerch(){
  //     var typeId=''
  //     var typeDataId=''
  //     queryTemplate(typeId, typeDataId, this.titel).then(res => {
  //       for(var i=0;i<res.data.length;i++){
  //         res.data[i].label =res.data[i].titel
  //         res.data[i].value =res.data[i].id
  //       }
  //       this.serchList=res.data
  //       // console.log("11111111",this.serchList)
  //     })
  //   },
  //
  //
  //   //获取用户信息
  //   getUser(){
  //     if( localStorage.getItem("Token")){ // 清除本地存储的 Token{
  //       getInfo().then(res => {
  //         localStorage.setItem('userName', res.user.userName);
  //         localStorage.setItem('userId', res.user.userId);
  //         localStorage.setItem('nickName', res.user.nickName);
  //         if(localStorage.getItem("nickName")){
  //           this.name= localStorage.getItem("nickName")
  //         }
  //       })
  //     }
  //   },
  //
  //   //跳转去详情
  //   details(id){
  //       this.$router.push("/indexDdetails?id="+id)
  //   },
  //
  //
  //   //去登录
  //   login() {
  //     this.$router.push('/login')
  //   },
  //   //去注册
  //   register(){
  //     this.$router.push('/register')
  //   },
  //   //退出登录
  //   quit(){
  //     this.$confirm('确定注销并退出系统吗？', '提示', {
  //       confirmButtonText: '确定',
  //       cancelButtonText: '取消',
  //       type: 'warning'
  //     }).then(() => {
  //       logout().then(res => {
  //         console.log(res)
  //         if(res.code == 200){
  //           this.$message({
  //             message: res.msg,
  //             type: 'success'
  //           });
  //           this.name=''
  //           localStorage.removeItem("Authorization"); // 清除本地存储的 Token
  //           localStorage.removeItem("nickName"); // 清除本地存储的 Token
  //           localStorage.removeItem("Token"); // 清除本地存储的 Token
  //           localStorage.removeItem("userName"); // 清除本地存储的 Token
  //           localStorage.removeItem("userId"); // 清除本地存储的 Token
  //         }
  //       })
  //     }).catch(() => {});
  //   },
  // },

}
</script>
<style scoped>
.box {
  width: 100%;
  height: auto;
}

.header {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
}

.header-left {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;

}

.header-left img {
  width: 145px;
}

.header-left span {
  font-size: 20px;
  font-weight: 500;
  color: #0265FF;
  margin-left: 5px;
}

.input {
  width: 62%;
  position: relative;
}

.input img {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 25px;
  top: 8px;
}

/deep/ .el-select {
  padding-left: 36px;
}

.header-right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.image{
  width: 70%;
  margin: 0 auto;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //justify-content: space-between;
}
.image img{
  width: 282px;
  height: 148px;
  margin-right: 40px;
}
.menu {
  width: 22px;
  height: 22px;
}

.sidebar {
  background-color: #f5f5f5;
  /* border-right: 1px solid #dcdcdc; */
  height: 100%; /* 确保侧边栏占满页面高度 */
  overflow: hidden; /* 禁止侧边栏滚动条显示 */
  margin-left: 10%;
}
.sidebar /deep/ .el-menu {
  height: 101%;
}
.active{
  color:#409EFF;
}
.el-menu .el-menu-item.active-menu,
.el-menu .el-submenu__title.active-menu {
  color: #0265ff !important; /* 蓝色字体 */
  font-weight: bold;
}
/deep/.el-menu{
  border-right: 0px !important;
}

/deep/.el-main {
  padding: 0;
}

.content {
  background: #fff;
  overflow-y: auto; /* 确保右侧内容有滚动条 */
  height: auto;
}

.right-box {
  width: 82%;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //justify-content: space-between;
  padding-left: 50px;
  box-sizing: border-box;
}

.right-box-content {
  width: calc(24% - -4.125rem * 2 / 3);
  height: 16.6125rem;
  margin-right: 1.875rem;
  background: #FFFFFF;
  box-shadow: 0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  cursor: pointer;
  margin-bottom: 1.875rem;
}

.right-box-content-top {
  width: 100%;
  height: 7.125rem;
  display: flex;
  justify-content: center;
}

.right-box-content-top img {
  width: 100%;
  height: 100%;
}

.right-box-content-top-content {
  width: 100%;
  margin: 0 auto;
  height: 6.7375rem;
  padding: 20px;
  box-sizing: border-box;
}

.right-box-content-top-content-title {
  font-size: 0.875rem;
  font-weight: bold;
  color: #333333;
  line-height: 1.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}
.right-box-content-top -content-title:hover {
  color: #409EFF;
}

.right-box-content-top-content p {
  font-size: 13px;
  font-weight: 500;
  color: #666666;
  overflow: hidden;
  line-height: 1.375rem;
  overflow: hidden;  /**超出隐藏**/
  text-overflow: ellipsis; /**文本超出时显示省略号**/
  display: -webkit-box;
  -webkit-box-orient: vertical;/**子元素排列 vertical（竖排）orhorizontal（横排）**/
  -webkit-line-clamp: 2;/**内容限制的行数**/
  text-align: -webkit-left
}

.right-box-content-bottom {
  width: calc(100% - 2.75rem);
  height: 2.75rem;
  border-top: 1px solid #E0E0E0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.right-box-content-bottom-left {
  display: flex;
  align-items: center;
}
.get-btn{
  width: 60px;
  height: 24px;
  line-height: 22px;
  border-radius: 12px;
  border: 1px solid #0265FF;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #0265FF;
}
.footer{
  width: 100%;
  height: 137px;
  background-color: #F4F5F7;
  //position: fixed;
  //bottom: 0;
}
.container{
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.container-left{
  width: 90%;
  height: 100%;
  margin-top: 30px;
}
.container-left a{
  margin-right: 20px;
  cursor:pointer;
  color: #666666;
  font-size: 13px;
  text-decoration: none;
}
.container-left-bottom{
  margin-top: 50px;
}
.container-right img{
  width: 120px;
  height: 120px;
  margin-top: 10px;
}
</style>
