import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import login from '../views/login.vue'
import forgotPassword from '../views/forgotPassword.vue'
import register from '../views/register.vue'
import indexDdetails from '../views/indexDdetails.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
    },
    {
        path: '/login',
        name: 'login',
        component: login,
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: forgotPassword,
    },{
        path: '/register',
        name: 'register',
        component: register,
    },{
    path: '/indexDdetails',
    name: 'indexDdetails',
    component: indexDdetails,
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
