import request from "../util/request";

// 查询系统应用模版详细
export function getDetails(id) {
    return request({
        url: '/system/application/' + id,
        method: 'get',
        headers: {
            isToken: true
        },
    })
}

// 新增用户收藏模版记录
export function addRecord(data) {
    return request({
        url: '/system/record',
        method: 'post',
        data: data,
        headers: {
            isToken: true
        },
    })
}


// 查询免费获取了吗
export function queryRecord(userId,applicationId) {
    return request({
        url: '/system/record/queryRecord?userId=' + userId+"&applicationId="+applicationId,
        method: 'get',
        headers: {
            isToken: true
        },
    })
}

//取消获取
export function delRecord(id) {
    return request({
        url: '/system/record/' + id,
        method: 'delete'
    })
}

