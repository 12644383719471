//首先导入刚刚封装好的request.js文件  路径存储根据自己修改
import request from "../util/request";



// 获取左侧菜单列表
export function queryList() {
    return request({
        url: '/web/queryList',
        method: 'get',
    })
}

//获取右侧数据列表
export function queryTemplate(typeId,typeDataId,titel) {
    return request({
        url: '/web/queryTemplate?typeId=' + typeId+'&typeDataId='+typeDataId +'&titel=' + titel,
        method: 'get',
    })
}


// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/getInfo',
        method: 'get',
        headers: {
            isToken: true
        },
    })
}

// 获取首页轮播图
export function banner() {
    return request({
        url: '/web/getSlideshow',
        method: 'get',
    })
}


//获取精品应用
export function premium() {
    return request({
        url: '/web/getQualityGoods',
        method: 'get',
    })
}

//获取最新上架
export function Newest() {
    return request({
        url: '/web/getNewest',
        method: 'get',
    })
}

