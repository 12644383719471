import axios from "axios";
import { Message, MessageBox } from "element-ui";
import router from "@/router"; // Vue Router，用于路由跳转
// const imageurl="http://192.168.0.104:8080";//图片地址
const imageurl="https://applicationmanager.elinkhoping.com/prod-api";
// 创建 Axios 实例
const instance = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API || "http://192.168.0.104:8080", // 替换为实际接口地址或环境变量
    baseURL: process.env.VUE_APP_BASE_API || "https://applicationmanager.elinkhoping.com/prod-api", // 替换为实际接口地址或环境变量
    timeout: 5000, // 请求超时时间
});
//  prod-api   dev-api
// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        // 获取本地存储的 Token
        const  token = localStorage.getItem("Token");
        // 如果 Token 存在，添加到请求头
        if (token) {
            config.headers["Authorization"] = 'Bearer '+ token;
            // console.log(`Authorization Header: "${config.headers["Authorization"]}"`);
        }

        return config; // 返回修改后的请求配置
    },
    (error) => {
        // 请求错误处理
        Message.error("请求错误，请稍后重试！");
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    (response) => {
        // 响应成功处理
        switch (response.data.code) {
            case 401: // 未授权，Token 过期
                MessageBox.confirm(
                    "登录已过期，您可以取消以留在此页面，或重新登录。",
                    "提示",
                    {
                        confirmButtonText: "重新登录",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                )
                    .then(() => {
                        localStorage.removeItem("Authorization"); // 清除本地存储的 Token
                        localStorage.removeItem("nickName"); // 清除本地存储的 Token
                        localStorage.removeItem("Token"); // 清除本地存储的 Token
                        localStorage.removeItem("userName"); // 清除本地存储的 Token
                        localStorage.removeItem("userId"); // 清除本地存储的 Token
                        router.push("/login"); // 跳转到登录页
                    })
                    .catch(() => {
                        localStorage.removeItem("userName"); // 清除本地存储的 Token
                        localStorage.removeItem("userId"); // 清除本地存储的 Token
                        localStorage.removeItem("Authorization"); // 清除本地存储的 Token
                        localStorage.removeItem("nickName"); // 清除本地存储的 Token
                        localStorage.removeItem("Token"); // 清除本地存储的 Token
                        Message.info("已取消操作");
                    });
                break;
        }
        return response.data;
    },
    async (error) => {
        const { response } = error;

        if (response) {
            // 根据状态码处理错误
            switch (response.code) {
                case 401: // 未授权，Token 过期
                    MessageBox.confirm(
                        "登录已过期，您可以取消以留在此页面，或重新登录。",
                        "提示",
                        {
                            confirmButtonText: "重新登录",
                            cancelButtonText: "取消",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            localStorage.removeItem("Authorization"); // 清除本地存储的 Token
                            router.push("/login"); // 跳转到登录页
                        })
                        .catch(() => {
                            Message.info("已取消操作");
                        });
                    break;

                case 400:
                    Message.error("请求参数错误！");
                    break;

                case 403:
                    Message.error("您没有权限访问！");
                    break;

                case 500:
                    Message.error("服务器内部错误！");
                    break;

                case 601:
                   Message.warning("非法操作！");
                    break;

                default:
                    Message.error(response.data.message || "未知错误！");
            }
        } else {
            // 网络连接错误或服务器无响应
            Message.error("网络连接失败，请稍后重试！");
        }

        return Promise.reject(error); // 返回错误信息
    }
);
// 导出 Axios 实例
export default instance;
export  {imageurl}
