import request from "../util/request";



// 重置密码获取验证码
export function getPwdCode(data) {
    return request({
        url: '/system/user/getPwdCode?phone=' + data.phone,
        // headers: {
        //     isToken: false
        // },
        method: 'post',
        // data: data
    })
}

//重置密码接口
export function updatePwd(phone,newPassword,verifyCode) {
    return request({
        url: '/system/user/updatePwd?phone=' + phone +"&newPassword=" + newPassword+"&verifyCode=" + verifyCode,
        // headers: {
        //     isToken: false
        // },
        method: 'put',
        // data: data
    })
}
