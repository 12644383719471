<template>
  <div class="box">
    <div class="header">
      <img src="../assets/logo1.png" alt="">
      好品易链
    </div>

    <div class="rightCont">
      <div class="title">忘记密码</div>
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules"  class="login-form">
        <el-form-item prop="phone">
          <el-input v-model="loginForm.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input  type="password" v-model="loginForm.newPassword" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item prop="verifyCode">
          <div style="display: flex;justify-content: space-between">
            <el-input style="width: 50%;" v-model="loginForm.verifyCode" placeholder="请输入验证码"></el-input>
            <el-button v-if="Code"   type="primary" style="width: 40%;" @click="code()">发送验证码</el-button>
            <el-button v-if="!Code" type="primary" :loading="true" style="width: 40%;">发送中</el-button>
          </div>
        </el-form-item>
      </el-form>
<!--      <el-input placeholder="请输入手机号/邮箱号"></el-input>-->
<!--      <div class="code">-->
<!--        <el-input placeholder="请输入图片验证码" ></el-input>-->
<!--        <img src="../assets/logo1.png" alt="">-->
<!--      </div>-->

      <div class="login">
        <el-button type="primary" style="width: 100%;" @click="chongzhi()">立即重置</el-button>
      </div>



    </div>

  </div>
</template>

<script>
import  {getPwdCode,updatePwd} from "../../api/forgotPassword"
export default {
  data() {
    return {
      Code:true,
      loginForm:{
        phone:'',
        newPassword:"",
        verifyCode:"",
      },
      loginRules:{
        pnone: [
          { required: true, trigger: "blur", message: "请输入您的手机号" }
        ],
        newPassword: [
          { required: true, trigger: "blur", message: "请输入您的新密码" }
        ],
        verifyCode: [
          { required: true, trigger: "blur", message: "请输入您的验证码" }
        ],
      }
    }
  },
  methods: {
    //获取验证码
    code(){
      if(this.loginForm.phone == ""){
        console.log("1111111")
        return this.$message.error('请输入手机号');
      }
      var obj={
        phone:this.loginForm.phone,
      }
      getPwdCode(obj).then(res=>{
        console.log(res)
        if(res.code == 200){
          this.Code=false
        }else {
          this.$message.error(res.msg);
        }
      })
    },

    //重置密码
    chongzhi(){
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          // this.loginForm.userName = this.loginForm.phonenumber;
          console.log( this.loginForm)
          updatePwd(this.loginForm.phone,this.loginForm.newPassword,this.loginForm.verifyCode).then(res=>{
            console.log(res)
            if(res.code == 200){
              this.$router.push("/login");
            }else {
              return this.$message.error(res.msg);
            }
          })
        }
      });
    },

  },

}
</script>
<style scoped>
.box {
  width: 100%;
  height: 100vh;
  cursor: pointer;
}
.header{
  width: 95%;
  margin: 0 auto;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-overflow: ellipsis;
  font-weight: 700;
  color: #0265FF;
  margin-top: 30px;
}
.header img{
  height: 1.5rem;
  margin-right: 5px;
}
.rightCont{
  display: inline-block;
  vertical-align: middle;
  width: 18.6875rem;
  height: 25.3125rem;
  background: #fff;
  box-shadow: 0 0 1rem rgba(40, 135, 203, 0.3);
  border-radius: .625rem;
  padding: 3rem 2.5rem 0;
  position: fixed;
  top: 20%;
  left: 40%;
}

.title{
  font-size: 1.375rem;
  margin-bottom: 1.5625rem;
  font-weight: 700;
  color: #000;
  white-space: nowrap;
  min-height: 1.875rem;
  display: flex;
}
.code{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.code /deep/.el-input{
  width: 55%;
}
.code img{
  width: 40%;
  height: 40px;
}
.login{
  margin-top: 20px;
}

</style>
